import { Component, inject, signal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { ApplicationInfoService } from '../core/application-info.service';
import { InvoiceService } from '../core/invoice.service';
import { StoreService } from '../core/store.service';
import { environment } from '../../environments/environment';
import { AnalyticsService } from '../core/analytics.service';

@Component({
    selector: 'app-main',
    standalone: true,
    template: `
        @if (!loading()) {
        <div class="master-wrapper-page {{ isHome() ? 'home home-slider' : '' }}">
            <app-header />
            <router-outlet />
        </div>
        <app-footer />
        <div id="goToTop" style="display: none"></div>
        }
    `,
    styles: ``,
    imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent]
})
export class MainComponent {
    storeService = inject(StoreService);
    appInfo = inject(ApplicationInfoService);
    router = inject(Router);
    activatedRoute = inject(ActivatedRoute);
    invoiceService = inject(InvoiceService);
    analyticsService = inject(AnalyticsService);
    dom = inject(DOCUMENT);

    hostName = 'https://shop.nadieselparts.com';

    isHome = signal(false);
    loading = this.storeService.loading;

    constructor() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe(async (event) => {
                if (event['canonical']) {
                    this.updateCanonicalUrl(this.hostName + event['canonical']);
                } else {
                    this.updateCanonicalUrl(this.hostName + this.router.url.split('?')[0]);
                }

                const title = event['title'];
                this.appInfo.setTitle(title);
                this.isHome.set(title === 'Home');

                await this.invoiceService.checkIfPaid();
            });

        this.router.events.forEach((item) => {
            if (item instanceof NavigationEnd) {
                this.setSource(item.url);

                // if (!environment.isProduction) return;

                this.analyticsService.gtag('page_view', { page_name: item.url });
            }
        });
    }

    setSource(url: string) {
        const utm = this.appInfo.getUtm(url);
        this.invoiceService.setSourceFromParams(utm);
    }

    updateCanonicalUrl(url: string) {
        const head = this.dom.getElementsByTagName('head')[0];
        let element = this.dom.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            element = this.dom.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', url);
    }
}

import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { StoreService } from '../core/store.service';
import { SelectBoxComponent } from '../shared/select-box.component';
import { ApplicationInfoService } from '../core/application-info.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-filters',
    standalone: true,
    imports: [CommonModule, FormsModule, SelectBoxComponent],
    template: `
        <div class="home-page-filters-wrapper ajax-filters-pro" x-style="margin-top: -73.9699px">
            <div class="home-page-filters">
                <div class="home-page-filter-selectors">
                    <div class="home-page-filter-selector">
                        <app-select-box
                            [items]="makes()"
                            [valueExpr]="'name'"
                            [placeholder]="'Make'"
                            (onValueChanged)="onMakeChanged($event)"
                        ></app-select-box>
                    </div>
                    <div class="home-page-filter-selector">
                        <app-select-box
                            [items]="models()"
                            [valueExpr]="'name'"
                            [placeholder]="'Model'"
                            (onValueChanged)="onModelChanged($event)"
                        ></app-select-box>
                    </div>
                    <div class="home-page-filter-selector">
                        <app-select-box
                            [items]="partTypes()"
                            [placeholder]="'Part Type'"
                            (onValueChanged)="onPartTypeChanged($event)"
                        ></app-select-box>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: ``,
})
export class FiltersComponent {
    router = inject(Router);
    appInfo = inject(ApplicationInfoService);
    storeService = inject(StoreService);

    selectedMake = signal<string>('');
    makes = computed(() => {
        return this.storeService.makes();
    });

    selectedModel = signal<string>('');
    models = computed(() => {
        if (!this.selectedMake()) return [];
        else if (this.selectedMake() === 'all') return this.storeService.makes().flatMap((m) => m?.models ?? []);

        const make = this.storeService.makes().find((m) => m.name === this.selectedMake());
        return make?.models ?? [];
    });

    partTypes = computed(() => {
        if (!this.selectedModel()) return [];
        const partTypeIds = this.storeService
            .partTypeModelMaps()
            .filter(
                (m) =>
                    (this.selectedMake() === 'all' || m.make === this.selectedMake()) &&
                    (this.selectedModel() === 'all' || m.model === this.selectedModel())
            )
            .map((m) => m.partTypeId);
        return this.storeService
            .partTypes()
            .filter((p) => partTypeIds.includes(p.id))
            .map((p) => {
                return {
                    id: p.id,
                    name: p.name,
                };
            });
    });

    search() {
        this.router.navigate(['/products']);
    }

    onMakeChanged(e: any) {
        const make = e.target.value;
        this.selectedMake.set(make);
    }

    onModelChanged(e: any) {
        const model = e.target.value;
        this.selectedModel.set(model);
    }

    onPartTypeChanged(e: any) {
        if (!e.target.value) return;

        const partTypeId = e.target.value !== 'all' ? e.target.value : '';
        const make = this.selectedMake() !== 'all' ? this.selectedMake() : '';
        const model = this.selectedModel() !== 'all' ? this.selectedModel() : '';

        if (environment.isWebComponent) {
            window.location.href = this.appInfo.addUtm(`${this.appInfo.shopUrl()}/search?make=${make}&model=${model}&partType=${partTypeId}`);
        } else
            this.router.navigate(['/search'], {
                queryParams: { make: make, model: model, partType: partTypeId },
            });
    }
}

import { Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartTypeGridComponent } from '../parts/part-type-grid.component';
import { FiltersComponent } from './filters.component';
import { PartTabsComponent } from '../parts/part-tabs.component';
import { SliderComponent } from './slider.component';
import { HeaderComponent } from '../layout/header.component';
import { Part } from '../core/models/part';
import { PartsService } from '../core/parts.service';
import { ApplicationInfoService } from '../core/application-info.service';

@Component({
    selector: 'app-home',
    standalone: true,
    template: `
        <div class="page home-page">
            <div class="page-body">
                <div class="topic-block">
                    <div class="topic-block-title welcome">
                        <h1>NA Diesel Engine Parts</h1>
                        <h2>Welcome to <strong>North&nbsp;American Diesel&nbsp;Parts</strong></h2>
                    </div>
                </div>
            </div>
        </div>

        <app-slider />
        <div class="master-wrapper-content">
            <div class="master-column-wrapper">
                <div class="center-1">
                    <app-filters />
                    <div class="page home-page">
                        <div class="page-body">
                            <app-part-type-grid />
                            <app-part-tabs [parts]="parts()" [pageSize]="8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: `
        .welcome {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-top: 30px;

            h1 {
                font: normal 30px / 40px "eurostile";
                // font-weight: 400;
            }

            img {
                height: 125px;
            }
        }
    `,
    imports: [CommonModule, PartTypeGridComponent, FiltersComponent, PartTabsComponent, SliderComponent, HeaderComponent]
})
export class HomeComponent implements OnInit {
    appInfo = inject(ApplicationInfoService);
    partsService = inject(PartsService);
    parts = signal<Part[]>([]);

    async ngOnInit() {
        this.appInfo.setTitle('Shop Diesel Engine Parts - Cummins, Caterpillar & More');
        this.appInfo.setMeta(
            'description',
            'Shop high-quality diesel engine parts for Cummins, Caterpillar, and Detroit engines. Find reliable parts for your diesel engine at NADieselParts.'
        );

        let parts = await this.partsService.getParts({ hasPictures: true, isFeatured: true, hasPrice: true, take: 8 });
        this.parts.set(parts);
    }
}

import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header.component';
import { FooterComponent } from './layout/footer.component';
import { HomeComponent } from './home/home.component';
import { FiltersComponent } from './home/filters.component';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationInfoService } from './core/application-info.service';
import { PartsService } from './core/parts.service';
import { StoreService } from './core/store.service';
import { InvoiceService } from './core/invoice.service';
import { UtilityService } from './core/utility.service';

@Component({
    selector: 'app-root',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [CommonModule, RouterOutlet, HttpClientModule, HeaderComponent, FooterComponent, HomeComponent, FiltersComponent],
    template: ` <router-outlet /> `,
    styles: ``
})
export class AppComponent implements OnInit {
    appInfo = inject(ApplicationInfoService);
    storeService = inject(StoreService);
    partsService = inject(PartsService);
    invoiceService = inject(InvoiceService);
    utilityService = inject(UtilityService);

    isHome = signal(false);

    async ngOnInit() {
        this.storeService.setLoading(true);
        await Promise.all([
            this.appInfo.getApplicationInfo(),
            this.utilityService.getWebPhotos(),
            this.partsService.getPartTypes(),
            this.partsService.getMakes(),
            this.partsService.getPartTypeModelMaps(),
            this.invoiceService.getFromStorage()
        ]);
        this.storeService.setLoading(false);
    }
}

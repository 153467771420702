import { CommonModule, NgIf } from '@angular/common';
import { Component, Input, OnInit, input, signal } from '@angular/core';

type carouselImage = {
    imageSrc: string;
    imageAlt: string;
}

@Component({
    selector: 'app-carousel',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div *ngIf="images && images.length" class="carousel-container">
            <img
                *ngFor="let image of images; let i = index"
                [src]="image.imageSrc"
                [alt]="image.imageAlt"
                [ngClass]="{ 'image-active': selectedIndex() === i }"
                class="fade"
            />
            <div *ngIf="indicators" class="carousel-dot-container">
                <span
                    *ngFor="let dot of images; let i = index"
                    class="dot"
                    [ngClass]="{ active: selectedIndex() === i }"
                    (click)="selectImage(i)"
                ></span>
            </div>

            <div *ngIf="controls" class="btn-carousel btn-prev" (click)="onPrevClick()">
                <i class="fas fa-arrow-circle-left icon-carousel icon-prev"></i>
            </div>

            <div *ngIf="controls" class="btn-carousel btn-next" (click)="onNextClick()">
                <i class="fas fa-arrow-circle-right icon-carousel icon-next"></i>
            </div>
        </div>
    `,
    styles: `
        .carousel-container {
            background-color: grey;
            position: relative;
            margin: auto;
            text-align: center;
            width: 100%;
            aspect-ratio: 1920 / 555;
            // border-radius: 15px;
            // box-shadow: 0 8px 16px #dce1e1;

            img {
                display: none;
                // border-radius: 15px;

                &.image-active {
                    display: block;
                    width: 100%;
                    // height: 400px;
                }
            }

            .fade {
                animation-name: fade;
                animation-duration: 1.5s
            }

            @keyframes fade {
                from {
                    opacity: 0.4;
                }
                to {
                    opacity: 1;
                }
            }
        }

        .carousel-dot-container {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            padding: 0;
            margin-bottom: 1rem;

            .dot {
                cursor: pointer;
                height: 13px;
                width: 13px;
                margin: 0 5px;
                background-color: #fff;
                border-radius: 50px;
                transition: opacity 0.6s ease;
                opacity: 0.5;

                &.active,
                &:hover {
                    opacity: 1;
                }
            }
        }

        .btn-carousel {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 5%;
            padding: 0;
            color: #fff;
            background: 0 0;
            cursor: pointer;

        }

        .btn-prev {
                left: 5px;
            }

        .btn-next {
            right: 5px;
        }

        .icon-carousel {
            z-index: 1010;
            opacity: 0.5;
            transition: all 0.15s ease;

            &:hover {
                color: #fff;
                opacity: 0.9;
            }

            &:active {
                opacity: 0.5s;
            }
        }

        .icon-prev, .icon-next {
            font-size: 35px;
        }
    `,
})
export class CarouselComponent implements OnInit {
    @Input() images: carouselImage[] = [];
    @Input() indicators = true;
    @Input() controls = true;
    @Input() autoSlide = false;
    @Input() slideInterval = 10000;

    selectedIndex = signal(0);

    ngOnInit() {
        if (this.autoSlide) {
            this.autoSlideImages();
        }
    }

    autoSlideImages() {
        setInterval(() => {
            this.onNextClick();
        }, this.slideInterval);
    }

    selectImage(index: number) {
        this.selectedIndex.set(index);
    }

    onPrevClick() {
        if (this.selectedIndex() === 0) this.selectedIndex.set(this.images.length - 1);
        else
            this.selectedIndex.update((index) => {
                index--;
                return index;
            });
    }

    onNextClick() {
        if (this.selectedIndex() === this.images.length - 1) this.selectedIndex.set(0);
        else
            this.selectedIndex.update((index) => {
                index++;
                return index;
            });
    }
}

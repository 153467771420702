import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { ApplicationInfoService } from '../core/application-info.service';
import { PartsService } from '../core/parts.service';
import { FiltersComponent } from '../home/filters.component';
import { Part } from '../core/models/part';
import { PartTabsComponent } from '../parts/part-tabs.component';
import { PartTypeGridComponent } from '../parts/part-type-grid.component';

@Component({
    selector: 'app-home-shared',
    standalone: true,
    imports: [FiltersComponent, PartTabsComponent, PartTypeGridComponent],
    template: `
        @if (!loading()) {
        <div class="nadiesel-shop">
            <div class="master-wrapper-content">
                <div class="center-1">
                    <app-filters />
                    <div class="page home-page">
                        <div class="page-body">
                            <app-part-type-grid />
                            <app-part-tabs [parts]="featuredParts()" [pageSize]="8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    `,
    styles: ``
})
export class HomeSharedComponent implements OnInit {
    appInfo = inject(ApplicationInfoService);
    partsService = inject(PartsService);
    loading = signal(false);
    featuredParts = signal<Part[]>([]);

    @Input()
    shopUrl: string = 'https://shop.northamericandieselparts.com';

    async ngOnInit() {
        this.loading.set(true);

        console.log('shopUrl', this.shopUrl);
        this.appInfo.shopUrl.set(this.shopUrl);
        this.appInfo.getApplicationInfo();

        const data = await Promise.all([
            this.appInfo.getApplicationInfo(),
            this.partsService.getPartTypes(),
            this.partsService.getMakes(),
            this.partsService.getPartTypeModelMaps()
        ]);

        let featuredParts = await this.partsService.getParts({ hasPictures: true, isFeatured: true, hasPrice: true, take: 8 });
        this.featuredParts.set(featuredParts);

        this.loading.set(false);
    }
}

import { bootstrapApplication, createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { createCustomElement } from '@angular/elements';
import { FeaturedPartsComponent } from './app/components/featured-parts.component';
import { environment } from './environments/environment';
import { FilterBarComponent } from './app/components/filter-bar.component';

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { HomeSharedComponent } from './app/components/home-shared.component';

config({ licenseKey });

if (!environment.isWebComponent) {
    bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
} else {
    console.warn('Running as Web Component');
    (async () => {
        const app = createApplication(appConfig).catch((err) => console.error(err));

        const homeElement = createCustomElement(HomeSharedComponent, {
            injector: (await app)!.injector,
        });
        customElements.define('home-shared', homeElement);

        const featuredPartsElement = createCustomElement(FeaturedPartsComponent, {
            injector: (await app)!.injector,
        });
        customElements.define('featured-parts', featuredPartsElement);

        const filterBarElement = createCustomElement(FilterBarComponent, {
            injector: (await app)!.injector,
        });
        customElements.define('filter-bar', filterBarElement);
    })();
}

import { ErrorHandler, Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable()
export class ToastErrorHandler extends ErrorHandler {
    constructor() {
        // The true parameter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
        // when an error happens. If we do not rethrow, bootstrap will always succeed.
        super();
    }

    override async handleError(error: any) {
        console.error(error);

        let message: string = error.rejection?.error?.Message || error.rejection?.message || error.message;
        if (error.rejection && error.rejection.entityErrors && Array.isArray(error.rejection.entityErrors)) {
            message = `${message}; Entity Errors:`;
            (error.rejection.entityErrors as any[]).forEach((entityError) => {
                message = `${message} ${entityError.errorMessage};`;
            });
        }

        if (message === '0: Unknown Error') message = 'Network error connecting to server';
        if (
            message.includes(
                'If an IAsyncActionFilter provides a result value by setting the Result property of ActionExecutingContext to a non-null value, then it cannot call the next filter by invoking ActionExecutionDelegate.'
            )
        )
            message = 'The data passed to the server was in an incorrect format.';

        if (message.includes('Entities may have been modified or deleted since entities were loaded')) {
            message = 'The item you are saving was modified by another user. Please refresh your browser and make your changes again.';
        }
        if (
            message.includes('Client side validation errors encountered - see the entityErrors collection on this object for more detail')
        ) {
            const validationErrors = (error.entityErrors as any[]).map((entityError) => entityError.errorMessage) as string[];
            message = `The item you are saving is invalid. Please fix the following error(s) and try again\n\n${validationErrors.join(
                '\n'
            )}`;
        }

        if (message.includes('ExpressionChangedAfterItHasBeenCheckedError')) return;
        if (message.includes(`Cannot read properties of undefined (reading '_updateBrokenRules')`)) return;
        if (message.includes(`Cannot read properties of undefined (reading 'resolve')`)) return;

        if (error?.status === 500) message = `Error communicating with the server: ${message}`;

        notify({
            position: { my: 'center top', at: 'center top', offset: '0 20', of: 'body' },
            message,
            closeOnClick: true,
            displayTime: 9000,
            width: '400px',
            type: 'error',
        });
    }
}

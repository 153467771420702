import { Component, OnInit, inject, signal } from '@angular/core';
import { FiltersComponent } from '../home/filters.component';
import { ApplicationInfoService } from '../core/application-info.service';
import { PartsService } from '../core/parts.service';

@Component({
    selector: 'app-filter-bar',
    standalone: true,
    imports: [FiltersComponent],
    template: ` @if (!loading()) {
        <app-filters />
        }`,
    styles: ``,
})
export class FilterBarComponent implements OnInit {
    appInfo = inject(ApplicationInfoService);
    partsService = inject(PartsService);
    loading = signal(false);

    async ngOnInit() {
        this.loading.set(true);

        this.appInfo.getApplicationInfo();

        await Promise.all([
            this.appInfo.getApplicationInfo(),
            this.partsService.getPartTypes(),
            this.partsService.getMakes(),
            this.partsService.getPartTypeModelMaps(),
        ]);

        this.loading.set(false);
    }
}

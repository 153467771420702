import { Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreService } from '../core/store.service';
import { PictureService } from '../core/picture.service';
import { CarouselComponent } from '../layout/carousel.component';

@Component({
    selector: 'app-slider',
    standalone: true,
    template: `
        <div class="slider-wrapper">
            <!-- <img class="home-logo" src="/assets/images/logo-transparent-white.png" /> -->
            <app-carousel [images]="homeSliderPhotos()" [indicators]="false" [autoSlide]="true" />
        </div>
    `,
    styles: ``,
    imports: [CommonModule, CarouselComponent],
})
export class SliderComponent implements OnInit {
    storeService = inject(StoreService);
    pictureService = inject(PictureService);
    homeSliderPhotos = signal<any[]>([]);

    ngOnInit() {
        const sliderPhotos = this.storeService.webPhotos().homeSliderPhotos.map((p) => {
            return { imageSrc: this.getSliderUrl(p), imageAlt: p };
        });
        this.homeSliderPhotos.set(sliderPhotos);
    }

    getSliderUrl = (fileName: string) => {
        return this.pictureService.getImageUrl('webPhoto', fileName);
    };
}

import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './layout/main.component';

export const routes: Routes = [
    {
        path: 'components',
        loadChildren: () => import('./components/routes').then((mod) => mod.COMPONENT_ROUTES),
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
                data: { title: 'Home' },
            },
            {
                path: 'search',
                loadComponent: () => import('./search.component').then((mod) => mod.SearchComponent),
                data: { title: 'Search' },
            },
            // {
            //     path: 'contact-us',
            //     loadComponent: () => import('./layout/contact-us.component').then((mod) => mod.ContactUsComponent),
            //     data: { title: 'Contact Us' },
            // },
            // {
            //     path: 'terms-and-conditions',
            //     loadComponent: () => import('./layout/terms-and-conditions.component').then((mod) => mod.TermsAndConditionsComponent),
            //     data: { title: 'Terms & Conditions' },
            // },
            // {
            //     path: 'warranty-information',
            //     loadComponent: () => import('./warranty/warranty-information.component').then((mod) => mod.WarrantyInformationComponent),
            //     data: { title: 'Warranty Information' },
            // },
            // {
            //     path: 'warranty-policy',
            //     loadComponent: () => import('./warranty/warranty-policy.component').then((mod) => mod.WarrantyPolicyComponent),
            //     data: { title: 'Warranty Policy' },
            // },
            {
                path: 'order-details/:invoiceNumber',
                loadComponent: () => import('./order-detail.component').then((mod) => mod.OrderDetailComponent),
                data: { title: 'Order Details' },
            },
            { path: 'products', loadChildren: () => import('./parts/routes').then((mod) => mod.PART_ROUTES) },
            { path: 'checkout', loadChildren: () => import('./checkout/routes').then((mod) => mod.CHECKOUT_ROUTES) },
        ],
    },

    { path: '**', redirectTo: '', pathMatch: 'full' },

    // {
    //     path: 'admin',
    // },
    // {
    //     path: 'account',
    // },
    // {
    //     path: 'checkout',
    // },
    // {
    //     path: 'shopping-cart',
    // },
    // {
    //     path: 'search',
    // },
];
